import React from 'react';
import { Link as RouterLink, graphql } from 'gatsby';
import { Box, Typography, Button } from '@mui/material';
import { FiArrowRight } from 'react-icons/fi';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Seo from '../components/Seo';
import Layout from '../layout';
import CenteredContainer from '../components/CenteredContainer';
import MarkdownContent from '../components/MarkdownContent';

const ArticleTemplate = ({ data }) => {
  const { strapiArticles } = data;
  const image = getImage(strapiArticles.cover?.localFile);

  return (
    <Layout offset={1}>
      <Seo title={strapiArticles.naslov} />
      <CenteredContainer>
        <Box textAlign="center" mb={3}>
          <Typography variant="overline">{strapiArticles.podnaslov}</Typography>
          <Typography variant="h3">{strapiArticles.naslov}</Typography>
        </Box>
        {image && strapiArticles.showCover && <GatsbyImage image={image} alt="" />}
        <Box maxWidth={strapiArticles.wide ? 996 : 680} mt={4} mb={2}>
          <MarkdownContent children={strapiArticles.sadrzaj} />
        </Box>
        {strapiArticles.callToAction && (
          <Button
            component={RouterLink}
            to={strapiArticles.callToAction.url}
            variant="contained"
            color="primary"
            endIcon={<FiArrowRight />}
            state={{ promocode: strapiArticles.callToAction.iMikroSifraAkcije }}
          >
            {strapiArticles.callToAction.text}
          </Button>
        )}
      </CenteredContainer>
    </Layout>
  );
};

export const query = graphql`
  query($url: String!) {
    strapiArticles(url: { eq: $url }) {
      id
      naslov
      podnaslov
      sadrzaj
      url
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, quality: 85, width: 950, height: 470)
          }
        }
      }
      strapiParent {
        url
      }
      callToAction {
        id
        text
        url
        iMikroSifraAkcije
      }
      wide
      showCover
    }
  }
`;

export default ArticleTemplate;
